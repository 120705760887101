import { CutlistOrderSummary } from '@cutr/constants/cutlist';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { buildFuseForAgents } from '@/api/agentOrders';
import { agentApi } from '@/api/backend/agent';

import { agentKeys } from './keys';

export const useCutlistsAgent = () => {
  return useQuery<CutlistOrderSummary[]>({
    queryKey: agentKeys.getCutlists,
    queryFn: () =>
      agentApi.getCutlists().then((data) => buildFuseForAgents(data)),
    initialData: [],
  });
};

export const useUpdateCutlistAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: string }) => agentApi.updateCutlist(id),
    mutationKey: agentKeys.update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: agentKeys.getCutlists });
    },
  });
};

export const useCancelCutlist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: string }) => agentApi.cancelCutlist(id),
    mutationKey: agentKeys.cancel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: agentKeys.getCutlists });
    },
  });
};
