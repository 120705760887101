import { CutlistOrder } from '@cutr/constants/cutlist';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import Card from '@/blocks/Card';
import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { FullPriceDataCard } from '@/blocks/Price';
import { useFetchMaterials, useHydrateStore } from '@/hooks';
import { MaterialGroupTabNav } from '@/interfaces/MaterialGroupTabNav';
import { Nesting } from '@/interfaces/Nesting';
import { PartTable } from '@/interfaces/PartTable';
import { Button } from '@/primitives/Button';
import { useCancelCutlist } from '@/queries/agent';
import { getCutlistQuery } from '@/queries/crud';

import { DetailsForm } from './AccountDetails';
import AgentDiscountModal from './AgentDiscountModal';
import { NotFound } from './Errors';
import { Header, SubHeader } from './Order';

export const AgentEditOrderPage = () => {
  useFetchMaterials();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { hydrateStore } = useHydrateStore();
  const [error, setError] = React.useState<string>();
  const { state, pathname } = useLocation();
  const [loading, setLoading] = React.useState<boolean>(
    state?.newOrder ? false : true
  );

  React.useEffect(() => {
    if (!id) {
      setError('Not found');
      return;
    }

    if (state?.newOrder) {
      return;
    }

    setLoading(true);

    queryClient
      .fetchQuery<CutlistOrder>(getCutlistQuery(id))
      .then((cutlist) => {
        hydrateStore(cutlist);
      })
      .catch((e) => {
        setError(e?.message || 'Could not fetch cutlist');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return null;
  if (error) return <div className="error">{error}</div>;

  if (pathname.includes('/edit')) {
    return <AgentEditOrder />;
  }

  return <NotFound />;
};

export const AgentEditOrder = (): JSX.Element => {
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const { mutateAsync: cancelCutlist } = useCancelCutlist();
  const { id } = useParams();
  const navigate = useNavigate();
  const { email } = useLeadDetails();

  const confirmCancelOrder = () => {
    if (!id) {
      return;
    }

    cancelCutlist({ id }).catch((e) => {
      if (e.response.status === 409) {
        toast.error(t('agent.deletionDialog.error'));
      }
    });
    setIsConfirmDialogOpen(false);
    navigate('/admin/orders');
  };

  return (
    <main>
      <Header />
      <SubHeader />
      <MaterialGroupTabNav />
      <section className="layout">
        <div>
          <PartTable />
          {email && <DetailsForm />}
        </div>
        <Aside />
      </section>
      <div style={{ maxWidth: '30%' }}>
        <Button variant="delete" onClick={() => setIsConfirmDialogOpen(true)}>
          {t('agent.deletionDialog.cta')}
        </Button>
      </div>
      <ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={confirmCancelOrder}
        title={t('agent.deletionDialog.title')}
        variant="alert"
      >
        {t('agent.deletionDialog.content')}
      </ConfirmationDialog>
    </main>
  );
};

const Aside = () => {
  return (
    <aside style={{ top: 0 }}>
      <AddDiscount />
      <Price />
      <Nesting />
    </aside>
  );
};

const AddDiscount = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { email } = useLeadDetails();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      {email && (
        <Button variant="secondary" className="full" onClick={handleOpenModal}>
          {t('agent.discount.buttonLabel')}
        </Button>
      )}
      <AgentDiscountModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

const Price = () => {
  const { t } = useTranslation();
  const { email } = useLeadDetails();

  return email ? (
    <Card>
      <FullPriceDataCard title={t('cutlist-summary.price.title')} />
    </Card>
  ) : null;
};
