import { CutlistOrderSummary } from '@cutr/constants/cutlist';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFuseInstance } from '@/api/agentOrders';
import { agentApi } from '@/api/backend/agent';
import Badge, { BadgeVariant } from '@/primitives/Badge';
import { Button, RouterButton } from '@/primitives/Button';
import { Download, Edit, Icon, View } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { Select } from '@/primitives/Select';
import { useCutlistsAgent } from '@/queries/agent';
import { saveBlob } from '@/utils/file';
import { currencyFormatter, dateFormatter } from '@/utils/format';

import styles from './AgentOrdersPage.module.css';

export const AgentOrdersPage = () => {
  const { data: orders } = useCutlistsAgent();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FiltersProps['filters']>({
    search: '',
    status: '',
    createdBefore: '',
    createdAfter: '',
    submittedBefore: '',
    submittedAfter: '',
  });

  const filteredOrders = useMemo(() => {
    if (!orders) return [];
    const {
      search: searchFilter,
      status: statusFilter,
      createdBefore: createdBeforeFilter,
      createdAfter: createdAfterFilter,
      submittedBefore: submittedBeforeFilter,
      submittedAfter: submittedAfterFilter,
    } = filters;

    let filteredOrders = orders.filter(
      (order) =>
        (statusFilter === '' || order.status === statusFilter) &&
        (createdBeforeFilter === '' ||
          new Date(order.createdAt) <= new Date(createdBeforeFilter)) &&
        (createdAfterFilter === '' ||
          new Date(order.createdAt) >= new Date(createdAfterFilter)) &&
        (submittedBeforeFilter === '' ||
          new Date(order.submittedAt || '') <=
            new Date(submittedBeforeFilter)) &&
        (submittedAfterFilter === '' ||
          new Date(order.submittedAt || '') >= new Date(submittedAfterFilter))
    );

    const searchResults = (input: string) => {
      const fuse = getFuseInstance('agentOrders');

      return fuse.search(input).map(({ item, score }) => {
        return { item, score };
      });
    };

    if (searchFilter) {
      const searchedOrders = searchResults(searchFilter);
      console.log(searchedOrders);
      const mappedMaterials = filteredOrders
        .filter((order) => searchedOrders.some((f) => f.item.id === order.id))
        .sort((a, b) => {
          const item1 = searchedOrders.find((order) => order.item.id === a.id);
          const item2 = searchedOrders.find((order) => order.item.id === b.id);
          const score1 = item1 ? Number(item1.score) : 0;
          const score2 = item2 ? Number(item2.score) : 0;
          return score1 - score2;
        });
      filteredOrders = searchFilter ? mappedMaterials : filteredOrders;
    }

    return filteredOrders;
  }, [orders, filters]);

  if (!orders) return <div>Loading...</div>;

  return (
    <main className={styles.content}>
      <Filters filters={filters} setFilters={setFilters} />
      <div className={styles.orderSection}>
        <table>
          <thead>
            <tr>
              {Boolean(filteredOrders.length) && (
                <>
                  {FIELDS({} as CutlistOrderSummary).map((field) => {
                    const { key } = field as Field;
                    return <th key={key + 'header'}>{t(`agent.${key}`)}</th>;
                  })}
                  <th>{t('agent.actions')}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order, i) => (
              <tr key={order.id}>
                {FIELDS(order).map(({ key, content }) => (
                  <td key={i + key + 'row'}>{content}</td>
                ))}
                <td>
                  <RowActions cutlist={order} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

type Field = {
  key: keyof CutlistOrderSummary;
  content: React.ReactNode;
};

const FIELDS: (order: CutlistOrderSummary) => Field[] = (order) => [
  { key: 'title', content: order.title },
  {
    key: 'shortId',
    content: order.shortId,
  },
  {
    key: 'companyName',
    content: order.companyName,
  },
  {
    key: 'customerName',
    content: order.customerName,
  },
  {
    key: 'customerEmail',
    content: order.customerEmail,
  },

  {
    key: 'status',
    content: <StatusWithHover order={order} />,
  },
  {
    key: 'totalAmountExclVAT',
    content: order.totalAmountExclVAT
      ? currencyFormatter(order.totalAmountExclVAT / 100)
      : '-',
  },
];

const StatusWithHover = ({ order }: { order: CutlistOrderSummary }) => {
  const { t } = useTranslation();
  const status = order.status;

  const getHoverText = () => {
    switch (status) {
      case 'draft':
        return {
          tooltip: `${t('agent.updatedAt')}: ${dateFormatter(order.updatedAt)}`,
          badgeVariant: 'default',
        };
      case 'quote_requested':
        return {
          tooltip: `${t('agent.submittedAt')}: ${dateFormatter(
            order.submittedAt as string
          )}`,
          badgeVariant: 'signal',
        };
      case 'quote_ready':
        return {
          tooltip: `${t('agent.quote_ready')}: ${dateFormatter(
            order.quoteReadyAt as string
          )}`,
          badgeVariant: 'new',
        };
      case 'order_placed':
        return {
          tooltip: `${t('agent.submittedAt')}: ${dateFormatter(
            order.submittedAt as string
          )}`,
          badgeVariant: 'success',
        };
      case 'cancelled':
        return {
          tooltip: `${t('agent.cancelledAt')}: ${dateFormatter(
            order.cancelledAt as string
          )}`,
          badgeVariant: 'alert',
        };
      default:
        return {
          tooltip: '',
          badgeVariant: 'default',
        };
    }
  };

  const { tooltip, badgeVariant } = getHoverText();

  return (
    <div className="tooltip" data-tooltip={tooltip}>
      <Badge variant={badgeVariant as BadgeVariant}>
        {t(`agent.${status}`)}
      </Badge>
    </div>
  );
};

type FiltersProps = {
  filters: {
    search: string;
    status: string;
    createdBefore: string;
    createdAfter: string;
    submittedBefore: string;
    submittedAfter: string;
  };
  setFilters: (filters: FiltersProps['filters']) => void;
};

const STATUS_OPTIONS = [
  { key: 'allStatuses', value: '', selected: true },
  { key: 'draft', value: 'draft' },
  { key: 'quote_requested', value: 'quote_requested' },
  { key: 'quote_ready', value: 'quote_ready' },
  { key: 'order_placed', value: 'order_placed' },
  { key: 'cancelled', value: 'cancelled' },
];

const Filters = ({ filters, setFilters }: FiltersProps) => {
  const { t } = useTranslation();
  const {
    search,
    status,
    createdBefore,
    createdAfter,
    submittedBefore,
    submittedAfter,
  } = filters;

  return (
    <div className={styles.filterSection}>
      <div className={styles.filterField}>
        <label>{t('agent.filters.search')}</label>
        <Input
          type="text"
          placeholder={t('agent.search')}
          value={search}
          onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.status')}</label>
        <Select
          options={STATUS_OPTIONS.map(({ key, value }) => ({
            label: t(`agent.${key}`),
            value,
            selected: value === status,
          }))}
          onChange={(e) => setFilters({ ...filters, status: e.target.value })}
          className={styles.filterSelect}
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.createdAfter')}</label>
        <Input
          type="date"
          value={createdAfter}
          onChange={(e) =>
            setFilters({ ...filters, createdAfter: e.target.value })
          }
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.createdBefore')}</label>
        <Input
          type="date"
          value={createdBefore}
          onChange={(e) =>
            setFilters({ ...filters, createdBefore: e.target.value })
          }
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.submittedAfter')}</label>
        <Input
          type="date"
          value={submittedAfter}
          onChange={(e) =>
            setFilters({ ...filters, submittedAfter: e.target.value })
          }
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.submittedBefore')}</label>
        <Input
          type="date"
          value={submittedBefore}
          onChange={(e) =>
            setFilters({ ...filters, submittedBefore: e.target.value })
          }
        />
      </div>
    </div>
  );
};

type RowActionsProps = {
  cutlist: CutlistOrderSummary;
};

const RowActions = ({ cutlist }: RowActionsProps) => {
  const [preparingZip, setPreparingZip] = React.useState(false);

  const handleDownload = async () => {
    setPreparingZip(true);
    agentApi
      .getCutlistZip(cutlist.id)
      .then((result) => {
        saveBlob(result, `${cutlist.shortId}.zip`);
      })
      .finally(() => {
        setPreparingZip(false);
      });
  };

  return (
    <div className={styles.actions}>
      <Button
        className="smaller pill snug outline"
        onClick={handleDownload}
        disabled={preparingZip ? true : false}
      >
        <Icon icon={<Download />} />
      </Button>
      {!cutlist.cancelledAt && (
        <>
          <RouterButton className="smaller pill snug outline" to={cutlist.id}>
            <Icon icon={<View />} />
          </RouterButton>
          <RouterButton
            className="smaller pill snug outline"
            to={`${cutlist.id}/edit`}
          >
            <Icon icon={<Edit />} />
          </RouterButton>
        </>
      )}
    </div>
  );
};
