import { CutlistOrderSummary } from '@cutr/constants/cutlist';
import Fuse from 'fuse.js';

type FuseInstances = {
  agentOrders: Fuse<CutlistOrderSummary>;
};
const fuseInstances = {} as FuseInstances;

export const getFuseInstance = (type: keyof FuseInstances) =>
  fuseInstances[type];

export function buildFuseForAgents(orders: CutlistOrderSummary[]) {
  const options: Fuse.IFuseOptions<CutlistOrderSummary> = {
    includeScore: true,
    keys: [
      'title',
      'id',
      'shortId',
      'companyName',
      'customerName',
      'customerEmail',
    ],
    useExtendedSearch: true,
  };

  fuseInstances.agentOrders = new Fuse(orders, options);

  return orders;
}
