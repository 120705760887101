import {
  CutlistOrder,
  CutlistRequestType,
  FindOrCreateUserLeadResponse,
} from '@cutr/constants/cutlist';
import { CutlistState } from '@cutr/constants/cutlist';
import { BackendCutlistGroup } from '@cutr/constants/cutlist-backend';
import * as Sentry from '@sentry/react';

import i18n from '@/i18n';
import { getCurrentConfig, getIsCutrEmbedded } from '@/theme';
import { createBackendGrouping } from '@/utils/nesting';

import { useLeadDetails } from '../account';
import { Address, useDeliveryAddress } from '../address';
import { dotCutlist } from '../importExport';
import { AuthState, isLoggedInSelector, useAuthStore } from '../login';
import { useMaterialGroupState } from '../materialsGroup';
import { useNestingStore } from '../nesting';
import { useCutlistState } from '../store';

const CUTLIST_FIELDS = [
  'title',
  'hasMaterials',
  'notes',
  'customerReference',
  'deliverLeftoverMaterials',
  'requestedDeliveryDate',
];
const USER_LEAD_DETAILS_FIELDS = [
  'name',
  'companyName',
  'phone',
  'clientNumber',
];

export class ApiError extends Error {
  response: { status: number; data: unknown };

  constructor(message: string, status: number, data: unknown) {
    super(message);
    this.name = 'ApiError';

    this.response = { status, data };
  }
}

export const checkError = async (res: Response, redirectToLogin = true) => {
  if (!res.ok) {
    const json = await res.json().catch(() => ({}));

    if (redirectToLogin && json.statusCode === 401) {
      localStorage.removeItem('cutlist-auth');
      window.location.href = `/login?redirect=${encodeURIComponent(
        window.location.pathname
      )}`;
      return res;
    }

    if (json.statusCode === 502) {
      const errors = json.errors || [];
      // @ts-expect-error - error handling
      console.error(errors.map((e) => e.message).join('\n\n'));
      throw new ApiError(json.message || res.statusText, res.status, {
        errors,
      });
    }

    throw new ApiError(json.message || res.statusText, res.status, json);
  }

  return res;
};

export const logError = (err: Error) => {
  Sentry.captureException(
    err,
    // @ts-expect-error Own error type
    { extra: err.response?.data }
  );
  throw err;
};

const baseUrl = import.meta.env.VITE_API_BASE_URL;

export const isAuthorized = () => {
  const config = getCurrentConfig();
  const isLoggedIn = isLoggedInSelector(useAuthStore.getState());

  if (config.source === 'CUTR-EMBED') return true;

  return isLoggedIn;
};

export const api = {
  createBaseUrl(resource = '', basePath = '') {
    return new URL(resource, baseUrl + basePath);
  },
  createUrl(path = '', includeConfig = false) {
    const url = this.createBaseUrl(path, '/v2/cutlist/');

    if (includeConfig) {
      if (i18n.resolvedLanguage) {
        url.searchParams.append('lng', i18n.resolvedLanguage);
      }

      const config = getCurrentConfig();
      const auth = useAuthStore.getState();

      url.searchParams.append('source', config.source);
      if (auth.token) {
        url.searchParams.append('token', auth.token);
      }
    }

    return url;
  },
  getHeaders({ includeAuth = true } = {}): Record<string, string> {
    const auth = useAuthStore.getState();

    const config = getCurrentConfig();
    const headers: Record<string, string> = {
      'X-Cutlist-Source': config.source,
    };

    if (i18n.resolvedLanguage) {
      headers['Accept-Language'] = i18n.resolvedLanguage;
    }

    if (includeAuth && auth.token) {
      headers['Authorization'] = `Bearer ${auth.token}`;
    }

    return headers;
  },
  materials() {
    const url = isAuthorized() ? 'materials' : 'unauthenticated/materials';

    return fetch(this.createUrl(url), {
      headers: { ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  pricing(cutlistGroups: BackendCutlistGroup[], clientNumber?: string) {
    if (getIsCutrEmbedded()) {
      return this.pricingEmbeddedCutlist(cutlistGroups);
    }

    const body = JSON.stringify({
      cutlistGroups,
      userLeadDetails: { clientNumber },
      finalise: false,
    });
    return fetch(this.createUrl(), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  pricingEmbeddedCutlist(cutlistGroups: BackendCutlistGroup[]) {
    const { orderId } = useCutlistState.getState();
    const body = JSON.stringify({
      cutlistGroups,
      cutlistId: orderId,
    });
    return fetch(this.createUrl(`embedded/${orderId}/price/`), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  async updateUserLeadLocale() {
    const authState = useAuthStore.getState();
    if (!authState.email) {
      return;
    }
    const body = JSON.stringify({ ...authState });
    fetch(this.createUrl('lead/locale', true), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .catch(logError);
  },
  saveEmbeddedCutlist(id = '', requestType: CutlistRequestType) {
    return fetch(this.createUrl(`embedded/${id}`), {
      method: id ? 'PUT' : 'POST',
      body: JSON.stringify({ ...serializeCutlist(), requestType }),
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
      credentials: 'include',
    })
      .then(checkError)
      .catch(logError);
  },
  getCutlist(id: string): Promise<CutlistOrder> {
    return fetch(this.createUrl(`${id}`), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  updateCutlist(id: string): Promise<CutlistOrder> {
    return fetch(this.createUrl(`cutlists/${id}`), {
      method: 'PUT',
      body: JSON.stringify(cutlistUpdateBodyData()),
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  getClientDetails(clientNumber: string, email: string, source: string) {
    const body = JSON.stringify({ email, source, clientNumber });
    return fetch(this.createUrl('client-details'), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json() as unknown)
      .catch(logError);
  },
  login(
    clientNumber: string,
    email: string,
    source: string,
    cutlistId?: string
  ): Promise<{ email: string; token: string; clientNumber: string }> {
    const body = JSON.stringify({ email, source, clientNumber, cutlistId });
    return fetch(this.createUrl('login'), {
      method: 'POST',
      body,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then((data) => {
        window.analytics.identify(data.userLeadId, {
          email,
          clientNumber,
          ticker: source,
        });
        if (data.locale) {
          i18n.changeLanguage(data.locale);
        }
        return data as { email: string; token: string; clientNumber: string };
      })
      .catch(logError);
  },
  devLogin() {
    return fetch(this.createUrl('dev-login'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then((data) => {
        if (data.locale) {
          i18n.changeLanguage(data.locale);
        }
        return data as unknown;
      })
      .catch(logError);
  },
  loginByKey(key: string) {
    const body = JSON.stringify({ key });
    return fetch(this.createUrl('loginByKey'), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders({ includeAuth: false }),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then((data) => {
        if (data.locale) {
          i18n.changeLanguage(data.locale);
        }
        return data as unknown;
      })
      .catch(logError);
  },
  thirdPartyLogin(token: string) {
    const body = JSON.stringify({ token });
    return fetch(this.createUrl('third-party-login'), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders({ includeAuth: false }),
      },
    })
      .then((res) => checkError(res, false))
      .then((res) => res.json())
      .then(({ data }) => {
        return data as AuthState & {
          cutlistId: string;
          missingArticleCodes: string[];
        };
      })
      .catch(logError);
  },
  loginByToken(token: string): Promise<AuthState> {
    const body = JSON.stringify({ token });
    return fetch(this.createUrl('loginByToken'), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders({ includeAuth: false }),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then((data) => {
        if (data.locale) {
          i18n.changeLanguage(data.locale);
        }
        return data;
      })
      .catch(logError);
  },
  loginBySession(): Promise<AuthState> {
    return fetch(this.createUrl('login-by-session'), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders({ includeAuth: false }),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .catch(logError);
  },
  parseCutlistCsv(data: string) {
    const language = i18n.resolvedLanguage;
    const body = JSON.stringify({ data, language });
    return fetch(this.createUrl('csv'), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json() as unknown)
      .catch(logError);
  },
  parseCutlistFreeFormText(input: string): Promise<CutlistState> {
    const body = JSON.stringify({ input });
    return fetch(this.createUrl('text'), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then((res) => res.data as unknown as CutlistState)
      .catch(logError);
  },
  getFreeFormCutlist(id: string): Promise<CutlistState> {
    return fetch(this.createBaseUrl(`${id}`, '/v2/free-form-cutlist/'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data.generatedData)
      .catch(logError);
  },
  markFreeFormCutlistCorrectness(
    id: string,
    isCorrect: boolean
  ): Promise<CutlistOrder> {
    const body = JSON.stringify({ isCorrect });
    return fetch(this.createBaseUrl(`${id}`, '/v2/free-form-cutlist/'), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  cutlist(
    id: string,
    isDraft: boolean
  ): Promise<CutlistOrder & { token?: string }> {
    const isCutrEmbedded = getIsCutrEmbedded();
    const path = isCutrEmbedded ? 'embedded' : 'cutlists';

    const url = `${path}/${id}`;

    return fetch(this.createUrl(isDraft ? id : url), {
      headers: { ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  features(): Promise<Record<string, boolean>> {
    return fetch(this.createBaseUrl('features'), {
      headers: { ...api.getHeaders() },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ features }) => features)
      .catch(logError);
  },
  findOrCreateUserLead(
    email: string,
    cutlistId: string
  ): Promise<FindOrCreateUserLeadResponse> {
    const body = JSON.stringify({ email, cutlistId });
    return fetch(this.createUrl('unauthenticated/lead'), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => {
        return data as FindOrCreateUserLeadResponse;
      })
      .catch(logError);
  },
};

export function pick(obj: Record<string, unknown>, keys: string[]) {
  return keys.reduce((res, key) => ({ ...res, [key]: obj[key] }), {});
}

function getAddresses(deliveryAddress: Address) {
  const addresses = [];

  addresses.push(getAddress(deliveryAddress));

  return addresses.filter(Boolean);
}

function getAddress(a: Address) {
  if (isEmpty(a)) return;
  return { ...a, name: a.contactName };
}

const isEmpty = (a: Address) => {
  return a.contactName === '' && a.line1 === '' && a.postalCode === '';
};

export function serializeCutlist() {
  const { source } = getCurrentConfig();
  const detailsState = useLeadDetails.getState();
  const cutlistState = useCutlistState.getState();
  const { sheetGroups } = useNestingStore.getState();
  const groupsState = useMaterialGroupState.getState();
  const deliveryAddress = useDeliveryAddress.getState();
  const { clientNumber } = useAuthStore.getState();
  const dotCutlistFileContent = JSON.stringify(
    dotCutlist(cutlistState, groupsState)
  );

  const cutlistAddresses = getAddresses(deliveryAddress);

  const cutlistGroups = createBackendGrouping(sheetGroups, groupsState.groups);

  const cutlist = pick(cutlistState, CUTLIST_FIELDS) as Partial<
    typeof cutlistState
  >;
  const userLeadDetails = pick(
    detailsState,
    USER_LEAD_DETAILS_FIELDS
  ) as typeof detailsState;

  const data = {
    ...cutlist,
    email: detailsState.email,
    addresses: cutlistAddresses,
    userLeadDetail: {
      ...userLeadDetails,
      phone: deliveryAddress.phone,
      name: userLeadDetails.name,
      clientNumber,
    },
    cutlistGroups,
    finalise: true,
    dotCutlistFileContent,
    requestedDeliveryDate: cutlist.requestedDeliveryDate || null,
  };
  return { ...data, source };
}

export function cutlistUpdateBodyData() {
  const createBodyData = serializeCutlist();
  // remove some of the fields that are not needed for update
  return {
    ...createBodyData,
    finalise: undefined,
    email: undefined,
    userLeadDetails: {
      ...createBodyData.userLeadDetail,
      clientNumber: undefined,
      email: undefined,
    },
  };
}
