/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { agentApi } from '@/api/backend/agent';
import { useAgentLoggedIn, useAuthStore } from '@/api/login';
import { ErrorMessage } from '@/blocks/ErrorMessage';
import { Button } from '@/primitives/Button';
import { Input } from '@/primitives/Input';

import styles from './AgentLogin.module.css';

export const AgentLogin = () => {
  const { loginAgent } = useAuthStore();
  const [error, setError] = React.useState('');
  const navigate = useNavigate();
  const isAgent = useAgentLoggedIn();
  const { pathname } = useLocation();
  console.log('pathname', pathname);

  if (isAgent && pathname === '/admin') {
    const path = '/admin/orders';
    return <Navigate to={path} />;
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    const formData = new FormData(e.currentTarget);
    setError('');
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    agentApi
      .login(email, password)
      .then(() => {
        return agentApi.sessionToToken();
      })
      .then((data) => {
        const { email, cutlistAdminSourceId, token, id } = data;
        const agent = { email, cutlistAdminSourceId, id };
        loginAgent({ agent, token });

        navigate('/admin/orders');
      })
      .catch((e) => {
        setError(e.message);
        console.error(e);
      });

    e.preventDefault();
  };

  return (
    <main className="content">
      <div className={styles.agentLogin}>
        <div className={styles.header}>
          <h2>Agent login</h2>
          {Boolean(error) && <ErrorMessage message={error} />}
          <p>Sign in to your agent account to see all cutlist orders.</p>
        </div>

        <form onSubmit={onSubmit}>
          <div>
            <label htmlFor="email">Email {'*'}</label>
            <div className={styles.help}>Email used for agent registration</div>
            <Input type="email" name="email" required />
          </div>
          <div>
            <label htmlFor="password">Password {'*'}</label>
            <Input type="password" name="password" required />
          </div>
          <Button type="submit">Submit</Button>
        </form>
      </div>
    </main>
  );
};
