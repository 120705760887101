/* eslint-disable i18next/no-literal-string */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAuthStore } from '@/api/login';
import { Button, RouterButton } from '@/primitives/Button';
import { Icon, List, Logout } from '@/primitives/Icons';

import styles from './AgentSidebar.module.css';

const routes = [{ label: 'Orders', path: 'orders', icon: <List /> }];

export const AgentSidebar = () => {
  const { agent } = useAuthStore();
  const location = useLocation();

  if (!agent) return null;

  return (
    <menu className={styles.sidebar}>
      <ol className={styles.navLinks}>
        {routes.map(({ label, path, icon }) => (
          <li
            key={path}
            className={classNames(
              styles.agentMenuOption,
              !location.pathname.includes(path) ? null : styles.isActive
            )}
          >
            <RouterButton
              to={path}
              style={{ fontSize: '1.5rem' }}
              icon={<Icon icon={icon} size={3} />}
              className="smaller"
            >
              {label}
            </RouterButton>
          </li>
        ))}
      </ol>

      <div className={styles.account}>
        <p>{agent.email}</p>
        <LogoutBtn />
      </div>
    </menu>
  );
};

const LogoutBtn = () => {
  const { logout } = useAuthStore();
  const { t } = useTranslation();

  const onLogout = () => {
    logout();
  };

  return (
    <Button
      className="whiteBorder"
      icon={<Icon icon={<Logout />} />}
      onClick={onLogout}
    >
      {t('common.logout')}
    </Button>
  );
};
