export const cutlistsKeys = {
  create: ['create'],
  update: ['update'],
  getOne: (cutlistId: string) => ['cutlist', cutlistId],
  getAll: ['cutlists'],
  duplicate: ['duplicate'],
  delete: ['delete'],
};

export const agentKeys = {
  login: ['agent', 'login'],
  getCutlists: ['agent', 'cutlists'],
  update: ['agent', 'update'],
  createCutlist: ['agent', 'create'],
  assignCutlistOwner: ['agent', 'assignCutlistOwner'],
  cancel: ['agent', 'cancel'],
};
