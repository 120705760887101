import {
  CutlistPart,
  Edgebanding as EdgebandingSides,
  EdgeProfile as EdgeProfileSides,
} from '@cutr/constants/cutlist';
import { CutlistOrder, CutlistPartType } from '@cutr/constants/cutlist';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApiError } from '@/api/backend';
import { useAgentLoggedIn, useIsLoggedIn } from '@/api/login';
import { getMaterial } from '@/api/materials';
import { aggregatePrices, lineItemsToMaterials } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { AddressReview } from '@/blocks/AddressInfo';
import Card from '@/blocks/Card';
import { EdgesTooltip } from '@/blocks/EdgesTooltip';
import { AggregatedMaterials } from '@/blocks/MaterialRow';
import { EstimatedTotal } from '@/blocks/Price';
import { useFetchMaterials } from '@/hooks';
import { CutlistNotesRecap } from '@/interfaces/CutlistNotesRecap';
import {
  Checkmark,
  Dashboard,
  GrainHorizontal,
  GrainNone,
  Icon,
  Layer,
  Menu,
} from '@/primitives/Icons';
import { useGetCutlist } from '@/queries/crud';
import { useCurrentFeatures } from '@/theme';
import { addrToPretty } from '@/utils/misc';

import tableStyles from '../interfaces/PartTable/styles.module.css';
import { Forbidden, NotFound } from './Errors';
import styles from './OrderReview.module.css';

export const readOnlyTableHeaders = [
  { labelKey: 'field.index.label', id: 'index' },
  { labelKey: 'field.partName.label', id: 'label', width: '220px' },
  { labelKey: 'field.quantity.label' },
  { labelKey: 'field.length.label' },
  { labelKey: 'field.width.label' },
  { labelKey: 'field.edgeband.label', id: 'edgebanding', colSpan: 4 },
  { labelKey: 'field.edgeProfile.label', id: 'edgeProfile', colSpan: 4 },
  { labelKey: 'field.grainDirection.label', id: 'grain' },
  { labelKey: 'field.labeling', id: 'createLabel' },
];

export const useReadOnlyHeaders = () => {
  const { hasEdgeProfiling, hasCreateLabels } = useCurrentFeatures();
  return readOnlyTableHeaders.filter(({ id }) => {
    if (id === 'edgeProfile') return hasEdgeProfiling;
    if (id === 'createLabel') return hasCreateLabels === 'part';
    return true;
  });
};

const CutlistContext = React.createContext<CutlistOrder>({} as CutlistOrder);

const Cutlist = () => {
  useFetchMaterials();
  const isLoggedIn = useIsLoggedIn();
  const isAgentLoggedIn = useAgentLoggedIn();
  const hasMaterials = useCutlistState((state) => state.hasMaterials);
  const { id } = useParams();
  const { data, isPending, error } = useGetCutlist(id);

  if (isPending) return null;

  const errorStatus = (error as ApiError)?.response?.status;
  if (errorStatus === 403) {
    return <Forbidden />;
  }
  if (errorStatus === 404) {
    return <NotFound />;
  }

  return (isLoggedIn || isAgentLoggedIn) && hasMaterials ? (
    <CutlistContext.Provider value={data as CutlistOrder}>
      <CutlistReadOnly />
    </CutlistContext.Provider>
  ) : null;
};

const CutlistReadOnly = () => {
  const { t } = useTranslation();

  return (
    <main className="content">
      <section className="layout">
        <div className={styles.review}>
          <h3>{t('review.subtitle')}</h3>
          <CutlistParts />
          <AddressRecap />
        </div>
        <AsideReadOnly />
      </section>
    </main>
  );
};

const CutlistParts = () => {
  const cutlist = useContext(CutlistContext);

  return (
    <section className={styles.order}>
      <Card>
        <div className="opposites">
          <h3 style={{ textTransform: 'capitalize' }}>{cutlist.title}</h3>
        </div>

        <div className={styles.summary}>
          <GroupList />

          <CutlistNotesRecap
            customerReference={cutlist.customerReference}
            notes={cutlist.notes}
            deliverLeftoverMaterials={cutlist.deliverLeftoverMaterials}
          />
        </div>
      </Card>
    </section>
  );
};

const GroupList = () => {
  const groupedParts = useGroupedParts();
  const { hasEdgeProfiling, supportedSheetEdgeTrims } = useCurrentFeatures();
  const { t } = useTranslation();

  return (
    <>
      {groupedParts.map((group) => {
        const {
          edgeProfile,
          sheetEdgeTrimType,
          materials,
          parts,
          title,
          id,
          continuousGrain,
          additionalProcessing,
        } = group;

        return (
          <article key={id}>
            <h4>{title}</h4>
            <AggregatedMaterials items={materials} />
            {continuousGrain && (
              <>
                <p>
                  <strong>
                    {t('cutlist-form.field.continuousGrain.title')}
                  </strong>
                </p>
                <p>{continuousGrain}</p>
              </>
            )}
            {additionalProcessing && (
              <>
                <p>
                  <strong>
                    {t('cutlist-form.field.additionalProcessing.title')}
                  </strong>
                </p>
                <p>{additionalProcessing}</p>
              </>
            )}
            {hasEdgeProfiling && edgeProfile !== 'none' && (
              <p>
                {t('cutlist-form.edgeProfile')}:{' '}
                <strong>
                  {t(`cutlist-form.edgeProfiling.${edgeProfile}`)}
                </strong>
              </p>
            )}
            {supportedSheetEdgeTrims.length > 0 && sheetEdgeTrimType && (
              <p>
                {t('cutlist-form.sheetEdgeTrimTitle')}:{' '}
                <strong>
                  {t(`cutlist-form.sheetEdgeTrimType.${sheetEdgeTrimType}`)}
                </strong>
              </p>
            )}
            <div className={tableStyles.orderSection}>
              <PartTable parts={parts}></PartTable>
            </div>
          </article>
        );
      })}
    </>
  );
};

const AddressRecap = () => {
  const cutlist = useContext(CutlistContext);
  const { i18n } = useTranslation();

  return (
    <>
      {cutlist.addresses.map((address, i) => (
        <AddressReview
          readOnly={true}
          info={addrToPretty(
            { contactName: address.name, ...address },
            i18n.resolvedLanguage
          )}
          key={i}
        />
      ))}
    </>
  );
};

const AsideReadOnly = () => {
  const cutlist = useContext(CutlistContext);
  const priceData = aggregatePrices(cutlist.pricingLineItems);
  const priceOverride = {
    totalAmountExclVAT: cutlist.totalAmountExclVAT,
    totalAmountInclVAT: cutlist.totalAmountInclVAT,
    priceData,
  };

  return (
    <aside>
      <EstimatedTotal
        priceOverride={priceOverride}
        readOnly={true}
        onAction={() => Promise.resolve()}
      />
    </aside>
  );
};

const PartTable = ({ parts }: { parts: CutlistPart[] }) => {
  return (
    <table>
      <Head />
      <Body parts={parts} />
    </table>
  );
};

const Head = () => {
  const headers = useReadOnlyHeaders();
  const {
    edgebandingUI: hasEdgebanding,
    hasEdgeProfiling,
    hasCreateLabels,
  } = useCurrentFeatures();
  const { t } = useTranslation();

  const headerText = (key?: string) => (key ? t(`cutlist-form.${key}`) : '');

  return (
    <thead>
      <tr>
        {headers.map((h, i) => {
          const edgesHeader = (
            <th
              key={h.labelKey || i}
              style={{ minWidth: h.width }}
              colSpan={h.colSpan}
            >
              <div>
                {t(`cutlist-form.${h.labelKey}`)}
                <EdgesTooltip />
              </div>
            </th>
          );

          switch (h.id) {
            case 'edgebanding':
              if (!hasEdgebanding) return null;
              return edgesHeader;
            case 'edgeProfile':
              if (!hasEdgeProfiling) return null;
              return edgesHeader;
          }

          return (
            <th key={h.labelKey || i} style={{ minWidth: h.width }} colSpan={1}>
              {headerText(h.labelKey)}
            </th>
          );
        })}
      </tr>
      {(hasEdgebanding || hasEdgeProfiling) && (
        <tr>
          <th />
          <th />
          <th />
          <th />
          <th />
          <th>{t('cutlist-form.field.edgebanding.sides.l1')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.l2')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.w1')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.w2')}</th>
          <th />
          {hasCreateLabels === 'part' && <th />}
        </tr>
      )}
    </thead>
  );
};

const Body = ({ parts }: { parts: CutlistPart[] }) => {
  return (
    <tbody>
      {parts.map((part) => {
        return <TableRow part={part} key={part.id} />;
      })}
    </tbody>
  );
};

const TableRow = ({ part }: { part: CutlistPart }) => {
  const {
    edgebandingUI: hasEdgebanding,
    hasEdgeProfiling,
    hasCreateLabels,
  } = useCurrentFeatures();

  const iconMap: Record<CutlistPartType, JSX.Element> = {
    panel: <Dashboard />,
    sheet: <Layer />,
    strip: <Menu />,
  };

  const edgebandingSides: (keyof EdgebandingSides)[] = [
    'length1',
    'length2',
    'width1',
    'width2',
  ];

  const edgeProfilingSides: (keyof EdgeProfileSides)[] = [
    'length1',
    'length2',
    'width1',
    'width2',
  ];

  return (
    <tr id={`panelRow-${part.id}`}>
      <td> {part.index + 1} </td>
      <td>
        {
          <span className="flexAlign gap-xs">
            <Icon
              className={styles.unflex}
              icon={iconMap[part.partType] || <Dashboard />}
              color="var(--gray-7)"
            />
            {part.label}
          </span>
        }
      </td>
      <td>
        <span>{part.quantity}</span>
      </td>
      <td>
        <span>{part.lengthMM}</span>
      </td>
      <td>
        <span>{part.widthMM}</span>
      </td>

      {hasEdgebanding &&
        edgebandingSides.map((side) => (
          <td key={side} style={{ color: 'var(--primary)' }}>
            {Boolean(part.edgebanding?.[side]) && (
              <Icon icon={<Checkmark />}></Icon>
            )}
          </td>
        ))}

      {hasEdgeProfiling &&
        edgeProfilingSides.map((side) => (
          <td key={side} style={{ color: 'var(--primary)' }}>
            {Boolean(
              part.edgeProfile?.[side] && part.edgeProfile?.[side] !== 'none'
            ) && <Icon icon={<Checkmark />}></Icon>}
          </td>
        ))}

      <td style={{ color: 'var(--primary)' }}>
        {part.grainDirection === 'along' ? (
          <Icon icon={<GrainHorizontal />} />
        ) : (
          <Icon icon={<GrainNone />} />
        )}
      </td>

      {hasCreateLabels === 'part' && (
        <td style={{ color: 'var(--primary)' }}>
          {Boolean(part.createLabel) && <Icon icon={<Checkmark />}></Icon>}
        </td>
      )}
    </tr>
  );
};

export const useGroupedParts = () => {
  const { pricingLineItems, materialGroups } = useContext(CutlistContext);
  const allMaterials = lineItemsToMaterials(pricingLineItems);

  return materialGroups.map((g) => {
    const groupParts = g.parts;

    const edgeband = groupParts
      .flatMap((p) => [
        p.width1Edgeband?.articleCode,
        p.width2Edgeband?.articleCode,
        p.length1Edgeband?.articleCode,
        p.length2Edgeband?.articleCode,
      ])
      .filter(Boolean);

    const currentGroupMaterials = allMaterials
      .filter(Boolean)
      .filter((material) =>
        [
          ...edgeband,
          g.bottomHpl?.articleCode,
          g.topHpl?.articleCode,
          g.core1Material?.articleCode,
          g.core2Material?.articleCode,
        ]
          .filter(Boolean)
          .includes(material.articleCode)
      );

    const material1 = getMaterial(g.core1Material?.articleCode);
    const material2 = getMaterial(g.core2Material?.articleCode);
    const name = [material1?.name, material2?.name].filter(Boolean).join(' / ');
    const title = [name, g.topHpl?.name, g.bottomHpl?.name]
      .filter(Boolean)
      .join(' / ');

    return {
      ...g,
      title,
      id: g.id,
      materials: currentGroupMaterials,
      core1: g.core1Material,
      parts: groupParts,
      edgeProfile: g.edgeProfileType,
    };
  });
};

export default Cutlist;
