import { CutlistOrder, CutlistOrderSummary } from '@cutr/constants/cutlist';
import { BackendCutlistGroup } from '@cutr/constants/cutlist-backend';

import { api, checkError, cutlistUpdateBodyData, logError } from './';

export const agentApi = {
  async login(email: string, password: string) {
    const body = JSON.stringify({ email, password });
    return fetch(api.createBaseUrl('', '/v2/sessions'), {
      method: 'POST',
      body,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  async sessionToToken() {
    return fetch(api.createUrl('session-to-token'), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },
  getCutlists: (): Promise<CutlistOrderSummary[]> => {
    return fetch(api.createUrl('agent/cutlists'), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  updateCutlist(id: string): Promise<CutlistOrder> {
    return fetch(api.createUrl(`agent/${id}`), {
      method: 'PUT',
      body: JSON.stringify(cutlistUpdateBodyData()),
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  getPrice(
    cutlistGroups: BackendCutlistGroup[],
    id: string
  ): Promise<CutlistOrder> {
    const body = JSON.stringify({
      cutlistGroups,
      finalise: false,
    });
    return fetch(api.createUrl(`agent/${id}/price`), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  getCutlistZip: (id: string): Promise<Blob> => {
    return fetch(api.createUrl(`agent/${id}/downloads/zip`), {
      headers: { 'Content-Type': 'blob', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.blob())
      .catch(logError);
  },

  createCutlist: (title: string): Promise<CutlistOrder> => {
    const body = JSON.stringify({ title });
    const url = 'agent/orders/new';

    return fetch(api.createUrl(url), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      body,
      credentials: 'include',
      method: 'POST',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  assignCutlistOwner: (email: string, cutlistId: string) => {
    const body = JSON.stringify({
      email,
    });
    return fetch(api.createUrl(`agent/orders/${cutlistId}/assign-owner`), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  cancelCutlist: (id: string): Promise<void> => {
    const url = `agent/${id}/cancel`;

    return fetch(api.createUrl(url), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
      method: 'POST',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(() => {})
      .catch(logError);
  },
};
