import { CutlistOrder, CutlistRequestType } from '@cutr/constants/cutlist';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { agentApi } from '@/api/backend/agent';
import { cutlistsApi } from '@/api/backend/cutlists';
import { useCutlistState, useSavingDraftStore } from '@/api/store';
import { useHydrateStore } from '@/hooks';

import { agentKeys, cutlistsKeys } from './keys';

export const useCreateEmptyCutlist = () => {
  const { mutateAsync, isPending } = useCreateCutlist();
  const navigate = useNavigate();
  const { hydrateStore } = useHydrateStore();

  const createNewCutlist = React.useCallback(async () => {
    const cutlist = await mutateAsync();
    hydrateStore(cutlist);

    navigate(`/cutlist/${cutlist.id}/parts`, { state: { newOrder: true } });
  }, []);

  return { createNewCutlist, isPending };
};

export const useCreateEmptyCutlistByAgent = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const title = t('cutlist-form.cutlistTitle');

  return useMutation({
    mutationFn: () => agentApi.createCutlist(title),
    mutationKey: agentKeys.createCutlist,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cutlistsKeys.getAll });
    },
  });
};

// use this for finer control over created cutlist
export const useCreateCutlist = (title?: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const newCutlistTitle = t('cutlist-form.cutlistTitle');

  return useMutation({
    mutationFn: () => cutlistsApi.createCutlist(title || newCutlistTitle),
    mutationKey: cutlistsKeys.create,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cutlistsKeys.getAll });
    },
  });
};

export const useUpdateCutlist = () => {
  const queryClient = useQueryClient();
  const store = useCutlistState();
  const { setIsSaving } = useSavingDraftStore();
  const { id } = useParams();

  const mutation = useMutation({
    onMutate: () => {
      setIsSaving(true);
    },
    onSettled: () => {
      setIsSaving(false);
    },
    mutationFn: ({
      requestType = 'place_order',
      submit = false,
      syncThirdParty = false,
    }: {
      requestType?: CutlistRequestType;
      submit?: boolean;
      syncThirdParty?: boolean;
    }) => {
      if (store.orderId !== id) return Promise.resolve({} as CutlistOrder);
      const cutlistId = id as string;
      setIsSaving(true);
      return cutlistsApi
        .updateCutlist(requestType, submit, cutlistId, syncThirdParty)
        .finally(() => setIsSaving(false));
    },
    mutationKey: cutlistsKeys.update,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return mutation;
};

export const useGetCutlist = (id?: string) => {
  return useQuery<CutlistOrder>(getCutlistQuery(id));
};

export const getCutlistQuery = (id?: string) => ({
  queryKey: cutlistsKeys.getOne(id as string),
  queryFn: () => cutlistsApi.getCutlist(id as string),
  enabled: !!id,
});

export const useDeleteCutlist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (cutlistId: string) => cutlistsApi.deleteCutlist(cutlistId),
    mutationKey: cutlistsKeys.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cutlistsKeys.getAll });
    },
  });
};

export const useDuplicateCutlist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (cutlistId: string) => cutlistsApi.duplicateCutlist(cutlistId),
    mutationKey: cutlistsKeys.duplicate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cutlistsKeys.getAll });
    },
  });
};

export const useAssignCutlistOwner = (cutlistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (email: string) =>
      agentApi.assignCutlistOwner(email, cutlistId),
    mutationKey: agentKeys.assignCutlistOwner,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: cutlistsKeys.getOne(cutlistId),
      });
    },
  });
};
