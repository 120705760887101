import { Theme } from '@cutr/constants/cutlist-theme';
import { useIsFetching } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import { useCutlistState } from '@/api/store';
import { useHydrateStore, useResetStore } from '@/hooks';
import { Button } from '@/primitives/Button';
import {
  AddUser,
  Checkmark,
  Cross,
  Icon,
  Plus,
  UserAvatar,
} from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useUpdateCutlistAgent } from '@/queries/agent';
import {
  useAssignCutlistOwner,
  useCreateEmptyCutlistByAgent,
} from '@/queries/crud';
import { useTheme, useThemeConfig } from '@/theme';

import { api, ApiError } from '../api/backend';
import styles from './AgentHeader.module.css';
import { Modal } from './Modal';

export const AgentHeader = () => {
  const config = useThemeConfig();
  const Logo = config.logo;
  const location = useLocation();
  const [isPending, setIsPending] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const resetStore = useResetStore();
  const { mutateAsync: updateCutlist } = useUpdateCutlistAgent();
  const { t } = useTranslation();

  const isEditing = location.pathname.includes('edit');
  const isOrdersPage = location.pathname.includes('orders') && !id;

  const { mutateAsync } = useCreateEmptyCutlistByAgent();
  const { hydrateStore } = useHydrateStore();

  const handleSave = async () => {
    setIsPending(true);
    if (!id) return;
    updateCutlist({ id })
      .then(() => {
        navigate(`/admin/orders`);
      })
      .finally(() => {
        resetStore();
        setIsPending(false);
      });
  };

  const handleDiscard = () => {
    resetStore();
    navigate(`/admin/orders`);
  };

  const handleCreateNewCutlist = async () => {
    const cutlist = await mutateAsync();
    hydrateStore(cutlist);
    navigate(`/admin/orders/${cutlist.id}/edit`, {
      state: { newOrder: true },
    });
  };

  return (
    <>
      <header>
        <a href={config.logoUrl || config.baseUrl} className="logo">
          <Logo />
        </a>
        <div
          className="flexAlign"
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginLeft: '3rem',
          }}
        >
          <div>{id && isEditing && <CutlistOwnerButton />}</div>

          <div style={{ marginLeft: 'auto' }} className="flexAlign">
            {isOrdersPage && (
              <Button
                icon={<Icon icon={<Plus />} />}
                className="smaller header"
                style={{
                  background: 'var(--header-cta-background)',
                  color: 'var(--header-cta-color)',
                }}
                onClick={() => handleCreateNewCutlist()}
              >
                {t('common.newOrder')}
              </Button>
            )}
            {isEditing && (
              <>
                <Button
                  variant="header"
                  className="whiteBorder"
                  icon={<Icon icon={<Checkmark />} />}
                  onClick={handleSave}
                  disabled={isPending}
                >
                  {'Save'}
                </Button>
                <Button
                  variant="header"
                  className="whiteBorder"
                  icon={<Icon icon={<Cross />} />}
                  onClick={handleDiscard}
                  disabled={isPending}
                >
                  {'Discard'}
                </Button>
              </>
            )}
            <Suspense fallback={'...'}>
              <LanguageSelector />
            </Suspense>
          </div>
        </div>
      </header>
    </>
  );
};

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const currentLng = i18n.resolvedLanguage;

  const languagesForThemes = (theme: Theme) => {
    if (theme === 'swisskrono') return ['en', 'de'];
    if (theme === 'cutr') return ['en', 'de', 'nl'];

    return ['en', 'nl'];
  };

  const changeLanguage = async (lng: string) => {
    i18n.changeLanguage(lng);
    await api.updateUserLeadLocale();
  };

  return (
    <div className="flexAlign gap-xxs">
      {languagesForThemes(theme).map((lng) => {
        const style =
          lng === currentLng
            ? {
                fontWeight: 'bold',
                textDecoration: 'underline',
              }
            : undefined;
        return (
          <Button
            key={lng}
            variant="iconHeader"
            onClick={async () => await changeLanguage(lng)}
            style={style}
          >
            {t(`common.lng.${lng}`)}
          </Button>
        );
      })}
    </div>
  );
};

const CutlistOwnerButton = () => {
  const { id } = useParams();
  const { mutateAsync: assignCutlistOwner } = useAssignCutlistOwner(
    id as string
  );
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const { status } = useCutlistState();
  const { init, email } = useLeadDetails();
  const [loading, setLoading] = React.useState(false);

  const isFetching = useIsFetching();
  if (isFetching) return null;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setLoading(true);
      const data = new FormData(e.currentTarget);
      const email = data.get('email');
      const cutlist = await assignCutlistOwner(email as string);
      init({
        ...cutlist.userLeadDetail,
        ...cutlist.userLead,
        notClient: false,
      });
      toast.success(t('agent.setCutlistOwnership.setOwnerSuccess', { email }));
      setLoading(false);
      setIsModalOpen(false);
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {status === 'draft' ? (
        <Button
          className="pill"
          variant="header"
          style={{
            background: 'var(--header-cta-background)',
            color: 'var(--header-cta-color)',
            minWidth: 'fit-content',
            pointerEvents: status === 'draft' ? 'auto' : 'none',
          }}
          icon={
            email ? <Icon icon={<UserAvatar />} /> : <Icon icon={<AddUser />} />
          }
          onClick={() => setIsModalOpen(true)}
        >
          {email
            ? t('cutlist-form.owner', { owner: email })
            : t('agent.setCutlistOwnership.setCutlistOwner')}
        </Button>
      ) : (
        <div
          className="flexAlign gap-xs"
          style={{ color: 'var(--header-color)' }}
        >
          <Icon icon={<UserAvatar />} />
          <strong>{t('cutlist-form.owner', { owner: email })}</strong>
        </div>
      )}

      <Modal
        title=""
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <div className={styles.modalContent}>
          <div>
            <h2>{t('agent.setCutlistOwnership.setCutlistOwner')}</h2>
            <p>{t('agent.setCutlistOwnership.setOwnerInstructions')}</p>
          </div>
          <form
            className={styles.formContainer}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              onSubmit(e);
            }}
          >
            <div>
              <label htmlFor="email">
                {t(`agent.setCutlistOwnership.email`)} {'*'}
              </label>
              <Input type="email" name="email" required />
            </div>
            <Button type="submit" disabled={loading}>
              {t('agent.setCutlistOwnership.setOwner')}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};
